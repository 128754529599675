import { createWebHistory, createRouter } from "vue-router";

import EnterDomain from './components/EnterDomain'
import HealthCheckResults from './components/HealthCheckResults'

const routes = [
    {
        path: '/',
        name: 'EnterDomain',
        component: EnterDomain,
    },
    {
        path: '/check/:domain',
        name: 'healthcheck',
        component: HealthCheckResults,
    },

    // Catch all 404
    { path: '/:pathMatch(.*)*', name: 'not-found', component: EnterDomain },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;