import api from './api'
//import csrf from './csrf'

export default {

    healthcheck(domain) {
        return api.get('/' + domain)
    },

    // async store(form) {
    //     await csrf.getCookie();
    //     return api.post('deploy', form)
    // },


}
