<template>
  <EnterDomain
    @search="doCheck"
    :loading="loading"
    :error="error"
  ></EnterDomain>

  <div
    v-if="!loading"
    class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8 pb-12"
  >
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="flex justify-between w-full px-4 py-6 mt-4 text-xl font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
      >
        <span>DNS Records</span>
        <ChevronUpIcon
          :class="open ? 'transform rotate-180' : ''"
          class="w-7 h-7 text-blue-500"
        />
      </DisclosureButton>
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-110 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-out"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-90 opacity-0"
      >
        <DisclosurePanel>
          <div
            v-if="response.authoritativeNameservers.length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-2 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  Authoritative Nameservers
                </p>
                <table class="table-auto text-left mt-2">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in response.authoritativeNameservers"
                      :key="record"
                    >
                      <td class="pr-12">{{ record }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            v-if="ARecords(response.query).length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  A Records
                </p>
                <table class="table-auto text-left mt-4">
                  <thead>
                    <tr>
                      <th class="pr-12">Address</th>
                      <th class="pr-12">TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in ARecords(response.query)"
                      :key="record.Address"
                    >
                      <td class="pr-12">{{ record.Address }}</td>
                      <td class="pr-12">{{ prettyTtl(record.Ttl) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <p class="line-clamp-2 text-sm text-gray-600">
                Reported by <strong>{{ nameserver }}</strong>
              </p>
            </div>
          </div>

          <div
            v-if="response.records.AAAA.length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  AAAA Records
                </p>
                <table class="table-auto text-left mt-4">
                  <thead>
                    <tr>
                      <th class="pr-12">Address</th>
                      <th class="pr-12">TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in response.records.AAAA"
                      :key="record.Address"
                    >
                      <td class="pr-12">{{ record.Address }}</td>
                      <td class="pr-12">{{ prettyTtl(record.Ttl) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <p class="line-clamp-2 text-sm text-gray-600">
                Reported by <strong>{{ nameserver }}</strong>
              </p>
            </div>
          </div>

          <div
            v-if="response.records.CNAME.length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  CNAME Records
                </p>
                <table class="table-auto text-left mt-4">
                  <thead>
                    <tr>
                      <th class="pr-12">Domain</th>
                      <th class="pr-12">TTL</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="record in response.records.CNAME"
                    :key="record.Address"
                  >
                    <tr>
                      <td class="pr-12">{{ record.Canonical }}</td>
                      <td class="pr-12">{{ prettyTtl(record.Ttl) }}</td>
                    </tr>
                    <tr v-for="A in ARecords(record.Canonical)" :key="A.Domain">
                      <td class="flex flex-row">
                        <svg
                          class="w-6 h-6 ml-1 mr-2 transform rotate-180 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
                          ></path>
                        </svg>
                        {{ A["Address"] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <p class="line-clamp-2 text-sm text-gray-600">
                Reported by <strong>{{ nameserver }}</strong>
              </p>
            </div>
          </div>

          <div
            v-if="response.records.MX.length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  MX Records
                </p>
                <table class="table-auto text-left mt-4">
                  <thead>
                    <tr>
                      <th class="pr-12">Exchange</th>
                      <th class="pr-12">Priority</th>
                      <th class="pr-12">TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in sortedMx" :key="record.Address">
                      <td class="pr-12">{{ record.Exchange }}</td>
                      <td class="pr-12">{{ record.Priority }}</td>
                      <td class="pr-12">{{ prettyTtl(record.Ttl) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <p class="line-clamp-2 text-sm text-gray-600">
                Reported by <strong>{{ nameserver }}</strong>
              </p>
            </div>
          </div>

          <div
            v-if="response.records.TXT.length > 0"
            class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
          >
            <div class="flex justify-between space-x-3">
              <div class="min-w-0 flex-1">
                <p class="text-lg font-medium text-gray-900 truncate">
                  TXT Records
                </p>
                <table class="table-auto text-left mt-4">
                  <thead>
                    <tr>
                      <th class="pr-12">Text</th>
                      <th class="pr-12">TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="record in response.records.TXT"
                      :key="record.Address"
                    >
                      <td class="pr-12">{{ record.Text }}</td>
                      <td class="pr-12">{{ prettyTtl(record.Ttl) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="mt-4">
              <p class="line-clamp-2 text-sm text-gray-600">
                Reported by <strong>{{ nameserver }}</strong>
              </p>
            </div>
          </div>
        </DisclosurePanel>
      </transition>
    </Disclosure>
    <Disclosure v-slot="{ open }">
      <DisclosureButton
        class="flex justify-between w-full px-4 py-6 mt-6 text-xl font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
      >
        <span>Health Checks</span>
        <ChevronUpIcon
          :class="open ? 'transform rotate-180' : ''"
          class="w-7 h-7 text-blue-500"
        />
      </DisclosureButton>
      <DisclosurePanel>
        <div
          :class="
            response.Certificate.IsValid ? ' bg-green-50 ' : ' bg-red-50 '
          "
          class="relative rounded-lg bg-gray-50 border border-gray-200 py-5 px-6 shadow-md mt-5 overflow-x-auto"
        >
          <div class="flex justify-between space-x-3">
            <div class="min-w-0 flex flex-row">
              <!-- Heroicon name: solid/check-circle -->
              <svg
                v-if="response.Certificate.IsValid"
                class="h-5 w-5 text-green-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                v-else
                class="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>

              <p class="text-lg font-medium text-gray-900 truncate ml-2 -mt-1 text-left">
                Valid SSL Certificate
              </p>
            </div>
          </div>
          <div class="mt-2 ml-7">
            <span v-if="response.Certificate.IsValid">
              <p class="line-clamp-2 text-md text-gray-800">
                A valid SSL certificate was detected. Good job!
              </p>
              <p class="line-clamp-2 text-md text-gray-800 mt-2">
              Expires {{ response.Certificate.Expiration }}
            </p>
              <code class="line-clamp-2 text-md text-gray-800">
              {{ response.Certificate.Certificate }}
            </code>
            </span>
            <p v-else class="line-clamp-2 text-md text-gray-800">
              We could not find a valid SSL certificate. You should ensure your site is using a valid SSL certificate and uses HTTPS for all connections.
            </p>
          </div>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script>
import healthcheck from "../apis/healthcheck";
import EnterDomain from "./EnterDomain.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/solid";

export default {
  components: {
    EnterDomain,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
  },
  name: "HealthCheckResults",
  data: function () {
    return {
      response: [],
      loading: true,
      error: false,
      domain: this.$route.params.domain,
    };
  },
  mounted() {
    if (this.$route.params.domain != "") {
      this.doCheck(this.$route.params.domain);
    }
  },
  methods: {
    doCheck(domain) {
      this.error = false;
      this.loading = true;
      this.response = [];
      healthcheck
        .healthcheck(domain)
        .then((response) => {
          this.response = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
          this.response = [];
        });
    },

    ARecords(domain) {
      return this.response.records.A.filter(
        (element) =>
          element.Domain.toString().replace(/\.$/, "") ===
          domain.toString().replace(/\.$/, "")
      );
    },
    prettyTtl(s) {
      const d = Math.floor(s / (3600 * 24));
      s -= d * 3600 * 24;
      const h = Math.floor(s / 3600);
      s -= h * 3600;
      const m = Math.floor(s / 60);
      s -= m * 60;
      const tmp = [];
      d && tmp.push(d + "d");
      if (h > 0) {
        (d || h) && tmp.push(h + "h");
      }
      if (m > 0) {
        (d || h || m) && tmp.push(m + "m");
      }
      if (s > 0) {
        tmp.push(s + "s");
      }
      return tmp.join(" ");
    },
  },
  computed: {
    sortedMx: function () {
      if (typeof this.response.records !== "undefined") {
        let mx = this.response.records.MX;
        return mx.sort((a, b) => a.Priority - b.Priority);
      }
      return null;
    },
    nameserver: function () {
      if (typeof this.response.nameserver !== "undefined") {
        return this.response.nameserver.toString().replace(/\.$/, "");
      }
      return null;
    },
  },
};
</script>

