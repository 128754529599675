import axios from 'axios';
import router from '../routes'

let api = axios.create({
    baseURL: process.env.VUE_APP_API_BASEURL // Set API endpoint
    // baseURL: '/api/v1/',
});

api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
api.defaults.withCredentials = true;

api.interceptors.response.use(undefined, function (error) {
    console.log('grr ugh grr ugh ugh ugh')
    switch (error.response.status) {
        case 401: // unauthenticated or tfa error
            console.log(error.response.data.error)
            if (error.response.data.error === 'Unauthenticated.') {
                localStorage.setItem('auth', 'false')
                router.push({ name: 'login' });
            } else {
                router.push({ name: 'two-factor-challenge' });
            }
            break
        // case 404: // resource not found
        //console.log('Endpoint not found. Please try refreshing the page.')
        //break
        // case 419: // session expired
        case 429: // rate limit
            break
        case 503: // down for maintenance
            //window.location.reload() // bounce the user to the login screen
            break;

        case 500:
            alert('Something went wrong. Please try again later')
            break;

        // allow individual requests to handle other error codes
        default:
            return Promise.reject(error) // devolve other error codes

    }
});

export default api