<template>
  <div class="py-4 sm:py-16 sm:pb-0 sm:-mt-6 sm:pt-0">
    <div class="relative sm:py-16">
      <div aria-hidden="true" class="hidden sm:block">
        <div
          class="absolute inset-y-0 left-0 w-1/2 bg-blue-700 opacity-30 rounded-r-3xl"
        />
        <svg
          class="absolute top-8 left-1/2 -ml-3"
          width="404"
          height="392"
          fill="none"
          viewBox="0 0 404 392"
        >
          <defs>
            <pattern
              id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                class="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="392"
            fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"
          />
        </svg>
      </div>
      <div
        class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
      >
        <div
          class="relative rounded-2xl px-6 py-10 bg-blue-600 overflow-hidden shadow-xl sm:px-12 sm:py-20"
        >
          <div
            aria-hidden="true"
            class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
          >
            <svg
              class="absolute inset-0 h-full w-full"
              preserveAspectRatio="xMidYMid slice"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 1463 360"
            >
              <path
                class="text-blue-500 text-opacity-40"
                fill="currentColor"
                d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
              />
              <path
                class="text-blue-700 text-opacity-40"
                fill="currentColor"
                d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
              />
            </svg>
          </div>
          <div class="relative">
            <div class="sm:text-center">
              <h2
                class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
              >
                Domain Health Check
              </h2>
              <p class="mt-6 mx-auto max-w-2xl text-lg text-blue-200">
                Enter your domain below to perform a basic health check and
                diagnose any potential issues.
              </p>
            </div>
            <form
              v-on:submit.prevent="doSearch"
              class="mt-12 sm:mx-auto sm:max-w-2xl sm:flex"
            >
              <div class="min-w-0 flex-1">
                <label for="cta_email" class="sr-only">Domain</label>
                <input
                  autocorrect="off"
                  autocapitalize="none"
                  required
                  v-model="domain"
                  id="domain"
                  type="text"
                  class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-600"
                  placeholder="Enter your domain"
                />
                <p class="mt-2 font-bold text-lg text-red-200" v-if="error">There was an error with the domain you entered.</p>
              </div>
              <div class="mt-4 sm:mt-0 sm:ml-3">
                <button
                  :disabled="loading"
                  :class="loading ? ' hover:bg-pink-600 bg-pink-600 ' : ' '"
                  class="block w-full rounded-md border border-transparent px-5 py-3 bg-pink-500 text-base font-medium text-white shadow hover:bg-pink-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-pink-600 sm:px-10"
                >
                  <span v-if="!loading">Check!</span>
                  <svg
                    class="h-6"
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 100 100"
                  >
                    <path
                      fill="#fff"
                      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    >
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "EnterDomain",
  props: {
    loading: Boolean,
    error: Boolean,
  },
  mounted() {
    this.domain = this.$route.params.domain;
  },
  data: function () {
    return {
      domain: "",
    };
  },
  methods: {
    doSearch() {
      if (typeof this.domain !== "undefined") {
        this.$emit("search", this.domain);
        this.$router.push({
          name: "healthcheck",
          params: { domain: this.domain },
        });
      }
    },
  },
};
</script>